import styled from 'styled-components';

export const TextLinContainer = styled.div`
  width: min-content;
  white-space: nowrap;
  > a {
    width: min-content;
    &:hover {
      text-decoration: underline !important;
    }
  }
`;
