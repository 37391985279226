import { useInsertionEffect } from 'react';

import { NextPage } from 'next';

import { AuthPage } from '~/pages-modules/auth';

import { withGuestControl } from '~/common/utils/with-guest-control';
import { useAuth } from '~/common/providers/auth';

export const getServerSideProps = withGuestControl(async () => {
  return {
    props: {},
  };
});

const GuestNextPage: NextPage = () => {
  const { clearLoginState } = useAuth();

  useInsertionEffect(() => {
    clearLoginState();
  }, []);

  return <AuthPage />;
};

export default GuestNextPage;
