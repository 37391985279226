import { FC, useCallback, useState, FormEventHandler } from 'react';

import { AuthForm } from '~/layouts/public/styles';

import { useToast } from '~/common/providers/toast';
import { useAuth } from '~/common/providers/auth';
import { useFormState } from '~/common/hooks/use-form-state';
import { ToastTypesEnum } from '~/common/enums/toast-types-enum';
import { InputTypesEnum } from '~/common/enums/input-types-enum';
import { sizes } from '~/common/constants/sizes';
import { TextLink } from '~/common/components/TextLink';
import { TextInput } from '~/common/components/TextInput';
import { Text } from '~/common/components/Text';
import { Button } from '~/common/components/Button';
import { api } from '~/common/api';

import { AppAuthDataInfoContainer, CreateAccountWrapper } from './styles';
import { ILoginRequest } from './interfaces/i-login-request';

export const AuthPage: FC = () => {
  const { loggedIn } = useAuth();
  const { fireToast } = useToast();
  const { assignLogin } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const { formData, handleInputChange } = useFormState<ILoginRequest>();

  const handleSubmit: FormEventHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      // get PushToken and PushTokenOS from localStorage
      const pushToken = localStorage.getItem('PushToken');
      const pushTokenOS = localStorage.getItem('PushTokenOS');
      await api
        .post('/Autenticacao/Login', {
          Login: formData.login,
          Senha: formData.password,
          PushToken: pushToken,
          PushTokenOS: pushTokenOS,
        })
        .then((response) => {
          if (!response.data.sucesso) {
            throw new Error();
          }

          const { accessToken, refreshToken, usuario } = response.data;
          assignLogin({
            accessToken,
            refreshToken,
            user: usuario,
          });
        })
        .catch(() => {
          fireToast({
            type: ToastTypesEnum.error,
            title: 'E-mail ou senha incorretos.',
            message:
              'Em caso de dúvidas, contate nosso suporte no e-mail contact@cryptocontrol.pro',
          });

          setLoading(false);
        });
    },
    [assignLogin, fireToast, formData]
  );

  if (loggedIn) return null;

  return (
    <>
      <AuthForm className="login-auth-form" onSubmit={handleSubmit}>
        <TextInput
          reverseColor
          name="login"
          label="E-mail"
          placeholder="username@gmail.com"
          value={formData.login}
          onChange={handleInputChange}
        />
        <TextInput
          reverseColor
          name="password"
          label="Senha"
          placeholder="password"
          type={InputTypesEnum.password}
          value={formData.password}
          onChange={handleInputChange}
        />
        <TextLink color="text_reverse" href="forgot-password">
          Esqueceu sua senha?
        </TextLink>
        <Button
          text="Entrar"
          type="submit"
          loading={loading}
          textColor="text"
        />
        <CreateAccountWrapper>
          <Text color="text_reverse" style={{ marginTop: '2px' }}>
            Ainda não tem uma conta?
          </Text>
          <TextLink
            href="signup"
            color="green"
            aStyle={{
              fontSize: sizes.typograph.sm,
              fontWeight: 'bold',
            }}
          >
            Criar grátis
          </TextLink>
        </CreateAccountWrapper>
      </AuthForm>
      <AppAuthDataInfoContainer className="app-auth-data-info">
        <Text>Autenticando...</Text>
      </AppAuthDataInfoContainer>
    </>
  );
};
