import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export const CreateAccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${sizes.spacing.xsm};
  @media (max-width: 938px) {
    flex-direction: column;
  }
`;

export const AppAuthDataInfoContainer = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  gap: ${sizes.spacing.lg};
  padding: 5%;
  min-width: 270px;
  max-width: 750px;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;