import Link from 'next/link';

import { CSSProperties } from 'styled-components';

import { IThemeColors } from '~/common/interfaces/i-theme-colors';
import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';

import { TextLinContainer } from './styles';
import { Text } from '../Text';

interface ITextLink {
  readonly href: string;
  readonly aStyle?: CSSProperties;
  readonly color?: keyof IThemeColors;
}
export const TextLink: IFCWithChildren<ITextLink> = ({
  children,
  href,
  aStyle,
  color = 'text',
}) => {
  return (
    <TextLinContainer>
      <Link href={href} passHref {...(aStyle && { style: aStyle })}>
        <Text size="sm" color={color}>
          {children}
        </Text>
      </Link>
    </TextLinContainer>
  );
};
